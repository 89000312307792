import service from '@/utils/request'
import qs from 'qs'

const widBaseUrl = process.env.VUE_APP_BASE_URL
const widgetURL = process.env.VUE_APP_SERVER_WIDGET
const storagePath = widgetURL + '/storage/' //https://local.yunyan.com/storage/
const resourceObjectUploadPath = 'sourceObject'
const resourceUploadPath = 'resourceImg'
const originalPath = storagePath + resourceUploadPath + '/' //https://local.yunyan.com/storage/resourceImg/
const thumbPath = originalPath + '360/' //https://local.yunyan.com/storage/resourceImg/360/
const resourceObjectPath = storagePath + resourceObjectUploadPath + '/' //https://local.yunyan.com/storage/sourceObject
const singleUploadUrl = widgetURL + '/files/upload'
const shopPath = storagePath + 'shop/'//店铺设置、装修访问目录
const goodsPath = storagePath + 'goods/'//店铺产品图片访问目录
const avatarUploadPath = 'avatar'
const avatarPath = storagePath + avatarUploadPath + '/360/' //https://local.yunyan.com/storage/avatar/360/

export function GetFileExt(filename) {
  return filename.toLowerCase().substring(filename.lastIndexOf('.'))
}

export function CheckFileExt(filename, accept) {
  const ext = GetFileExt(filename)
  return accept.indexOf(ext) > -1
}

export function ShowImgPath(file, size, path) {
  size = (size) ? size + '/' : ''
  path = (path) ? path + '/' : ''
  return widgetURL + '/files/show/' + path + size + file
}

export function GetStoragePath() {
  return storagePath
}

export function SingleUpload(data) {
  data.type = 'single'
  return service({
    url: singleUploadUrl,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    transformRequest: [function () {
      return data
    }],
    data: qs.stringify(data)
  })
}

export default {
  CheckFileExt: (...args) => CheckFileExt(...args),
  GetFileExt: (...args) => GetFileExt(...args),
  widBaseUrl: widBaseUrl, //全局域名地址
  widgetURL:widgetURL, //全局widgetURL域名地址
  avatarPath: avatarPath, //头像访问地址
  avatarUploadPath: avatarUploadPath, //头像上传目录
  storagePath: storagePath, //存储根目录绝对地址
  resourceObjectUploadPath: resourceObjectUploadPath, //Obj文件上传目录
  resourceUploadPath: resourceUploadPath, //素材文件上传目录
  thumbPath: thumbPath, //缩略图访问绝对地址
  originalPath: originalPath, //素材源文件访问绝对地址
  resourceObjectPath: resourceObjectPath, //源文件访问绝对地址
  singleUploadUrl: singleUploadUrl, //单个文件上传地址
  shopPath:shopPath,
  goodsPath:goodsPath,
  SingleUpload: (...args) => SingleUpload(...args),
  ShowImgPath: (...args) => ShowImgPath(...args)
}
