import {createApp} from 'vue'
//import 'default-passive-events'//todo antui中部分组件 报Unable to preventDefault inside passive event listener invocation 错误，暂时去掉此引用以解决报错，如果其它组件运行不正常，试试恢复此行
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import Antd, {message} from 'ant-design-vue'
import router from './router'
import store from './store'
import vue3d from 'vue3d'
import canVue from '@canvue/canvue'
import User from '@/utils/user'
import Tools from '@/utils/tools'
import '../src/assets/icoCss/iconfont.css'
import Editor from "@/components/editor"
//登录校验放到了路由上
router.beforeEach((to, from, next) => {
  if (to.meta.requireLogin) {
    //  页面需要登录后访问
    if (User.isLogin()) {	///isLogin() 方法在user.js中
      next() // 表示已经登录
    } else {
      next({
        name: 'home'
      })
    }
  } else {
    next()
  }
})

const app = createApp(App)
app.use(Antd)
app.use(router)
app.use(store)
app.use(vue3d, {globalImport: true})
app.use(Editor)
app.use(canVue)
app.config.globalProperties.$msg = message
app.config.globalProperties.$user = User
app.config.globalProperties.$tools = Tools

app.mount('#app')
