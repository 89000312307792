const subRouter = [{
  path: '/product',
  children: [
    {
      path: '',
      name: 'product',
      component: () => import('../views/product/ProductsView.vue')
    },
    {
      /* 店铺主页*/
      path: 'shop',
      name: 'shop',
      component: () => import('@/views/product/Shop.vue')
    },
    {
      /* 店铺产品*/
      path: 'goods',
      name: 'goods',
      component: () => import('@/views/product/ShopGoods.vue')
    },
    {
      /* 设计师主页已登录的用户*/
      path: 'detail',
      name: 'detail',
      component: () => import('@/views/product/ProductsDetailView.vue')
    },
    {
      /* 设计师主页已登录的用户*/
      path: 'cart',
      name: 'cart',
      meta: {
        requireLogin: true
      },
      component: () => import('@/views/product/Cart.vue')
    },
    {
      path: 'createOrder',
      name: 'createOrder',
      meta: {
        requireLogin: true
      },
      component: () => import('@/views/product/CreateOrder.vue')
    },
    {
      path: 'pay',
      name: 'pay',
      meta: {
        requireLogin: true
      },
      component: () => import('@/views/product/PayOrder.vue')
    }
  ]
}]

export default subRouter
