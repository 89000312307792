const subRouter = [{
  path: '/competition',
  children: [
    {
      path: '',
      name: 'competition',
      component: () => import('@/views/competition/CompetitionListView.vue')
    }, {
      path: 'work_list',
      name: 'competition_work',
      component: () => import('@/views/competition/CompetitionWListView.vue')
    }, {
      path: 'end_time',
      name: 'competition_end_time',
      component: () => import('@/views/competition/CompetitionListEndTimeView.vue')
    }, {
      path: 'details',
      name: 'competition_details',
      component: () => import('@/views/competition/CompetitionDetailsView.vue')
    }, {
      path: 'register',
      name: 'competition_register',
      meta: {
        requireLogin: true
      }, component: () => import('@/views/competition/CompetitionRegister.vue')
    }
  ]
}]

export default subRouter
