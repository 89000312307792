const subRouter = [{
  path: '/user_template',
  children: [
    {
      path: '',
      name: 'template',
      component: () => import('@/views/template/Template')
    },
    {
      path: 'detail',
      name: 'template_detail',
      component: () => import('@/views/template/Detail')
    }
  ]
},
  {
    path: '/model',
    children: [
      {
        path: 'scene',
        name: 'scene',
        component: () => import('@/views/template/SceneCenter')
      },
      {
        path: 'scene_detail',
        name: 'scene_detail',
        component: () => import('@/views/template/SceneDetail')
      }
    ]
  }
]

export default subRouter
