import store from "./stores"
import IconFont from "@unjuanable/iconfont";

import "vue3-colorpicker/style.css";

export default {
  install(app) {
    app.use(store)
    app.use(IconFont, "//at.alicdn.com/t/c/font_481557_butcon6ut36.css")
  }
}
