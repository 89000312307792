const subRouter = [{
  path: '/reviewer',
  redirect: '/reviewer/login',
  children: [
    {
      path: 'login',
      name: 'reviewer_login',
      component: () => import('@/views/reviewer/LoginView.vue')
    },
    {
      path: 'competition',
      name: 'reviewer_competition',
      component: () => import('@/views/reviewer/CompetitionListView.vue')
    }
  ]
}]

export default subRouter
