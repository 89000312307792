<template>
  <router-view :key="$route.fullPath"/>
</template>
<script setup>
import {getCurrentInstance, onMounted} from "vue";

const {proxy} = getCurrentInstance()
onMounted(() => {
  proxy.$user.checkLogin()
})
</script>
<style lang="less">
</style>
