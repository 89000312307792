import {defineStore} from 'pinia'
import {useDataStore} from "@/components/editor/stores/data";
import {computed, reactive, ref, toRaw, watch} from "vue";
import {useEditorStore} from "@/components/editor/stores/editor";

/**
 * 装饰定制数据
 * @type any
 */
export const useDecorateStore = defineStore('decorate', () => {
  const data = useDataStore()
  const editor = useEditorStore()

  const selectedKey = ref(null) // 选中的器型
  const uvs = reactive({}) // 三维模型对应的UVS数据
  const stages = reactive({})

  const uv = computed(() => {
    if (uvs.hasOwnProperty(selectedKey.value)) {
      return uvs[selectedKey.value]
    } else {
      return null
    }
  })

  const activeAreas = computed(() => {
    if (uvs.hasOwnProperty(selectedKey.value)) {
      return uvs[selectedKey.value].areas
    } else {
      return []
    }
  })

  function init() {
    for (let key in data.uvs) {
      uvs[key] = {
        id: key,
        areas: toRaw(data.uvs[key].areas),
        copy: null,
        // map: useProjectedCanvas("pattern", {
        //   uuid: key,
        //   width: 1024,
        //   height: 1024,
        //   bgColor: 'rgba(255,255,255,0)',
        //   delay: 0
        // }),
        map: null, // 对应VProject组件的画布
        mapStagesData: [], // 贴图数据的json对象
      }
      for (let index in uvs[key].areas) {
        let id = key + "#" + index
        uvs[key].areas[index].id = id
        stages[id] = uvs[key].areas[index]
        if (stages[id].width < 200) {
          stages[id].zoom = 250 / stages[id].width
        } else {
          stages[id].zoom = 500 / stages[id].width
        }
      }
    }
  }

  watch(() => data.uvs, (val) => {
    init()
  })

  watch(() => editor.selected.key, (key) => {
    selectedKey.value = key
  })

  return {
    uvs, uv, activeAreas, stages,
  }
})
