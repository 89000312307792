import service from '@/utils/request'
import qs from 'qs'

const baseURL = process.env.VUE_APP_SERVER_MIDEND

export function GetWechatLoginUrl() {
  return baseURL + '/sign/wechat_login';
}

export function GetWechatBindUrl() {
  return baseURL + '/sign/wechat_bind';
}

export function WechatLoginByAuthCode(data) {
  return service({
    url: baseURL + '/sign/wechat_login_by_auth_code',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function isBindWechat(data) {
  return service({
    url: baseURL + '/sign/is_bind_wechat',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function UnbindWechat(data) {
  return service({
    url: baseURL + '/sign/unbind_wechat',
    method: 'post',
    data: qs.stringify(data)
  })
}


export function SignIn(data) {
  return service({
    url: baseURL + '/sign/in',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function SignUp(data) {
  return service({
    url: baseURL + '/sign/up',
    method: 'post',
    data: qs.stringify(data)
  })
}

export async function isLogin(data) {
  return service({
    url: baseURL + '/sign/isLogin',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function SignOut(data) {
  return service({
    url: baseURL + '/sign/logout',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function GetPasswordValidPhone(data) {
  return service({
    url: baseURL + '/sign/get_password_valid_phone',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function GetPasswordModify(data) {
  return service({
    url: baseURL + '/sign/get_password_modify',
    method: 'post',
    data: qs.stringify(data)
  })
}

//验证码登录
export function UseCodeLogin(data) {
  return service({
    url: baseURL + '/sign/use_code_login_in',
    method: 'post',
    data: qs.stringify(data)
  })
}

export default {
  SignIn: (...args) => SignIn(...args),
  SignUp: (...args) => SignUp(...args),
  SignOut: (...args) => SignOut(...args),
  isLogin: (...args) => isLogin(...args),
  GetPasswordValidPhone: (...args) => GetPasswordValidPhone(...args),
  GetPasswordModify: (...args) => GetPasswordModify(...args),
  GetWechatLoginUrl: (...args) => GetWechatLoginUrl(...args),
  GetWechatBindUrl: (...args) => GetWechatBindUrl(...args),
  UseCodeLogin: (...args) => UseCodeLogin(...args),
  WechatLoginByAuthCode: (...args) => WechatLoginByAuthCode(...args),
  isBindWechat: (...args) => isBindWechat(...args),
  UnbindWechat: (...args) => UnbindWechat(...args),
}
