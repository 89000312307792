import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import userRouter from "@/router/user";
import competitionRouter from '@/router/competition'
import signRouter from '@/router/sign'
import mobileRouter from '@/router/mobile'
import yasoRouter from '@/router/yaso'
import templateRouter from '@/router/template'
import productRouter from '@/router/product'
import reviewerRouter from '@/router/reviewer'

const routes = [{
  path: '/',
  name: 'home',
  component: () => import('../views/HomeView.vue')
},
  {
    path: '/no_pub', name: 'no_pub', component: () => import('../views/NoPubView.vue')
  }, {
    path: '/logout', name: 'logout', component: () => import('../views/LogoutView.vue')
  }, {
    path: '/original_design', name: 'original_design', component: () => import('../views/OriginalDesignView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/article_details', name: 'article_details', component: () => import('../views/ArticleDetailsView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/confirm_purchase', name: 'confirm_purchase', component: () => import('../views/ConfirmPurchaseView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/company_approve', name: 'company_approve', component: () => import('../views/CompanyApproveView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/company_homepage', name: 'company_homepage', component: () => import('../views/CompanyHomepageView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/company_issue', name: 'company_issue', component: () => import('../views/CompanyIssueView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/company_model', name: 'company_model', component: () => import('../views/CompanyModelView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/design_academy', name: 'design_academy', component: () => import('../views/DesignAcademyView.vue')
  }, {
    /* 设计学苑内容页 */
    path: '/design_academy_content',
    name: 'design_academy_content',
    component: () => import('../views/DesignAcademyContent.vue')
  }, {
    /* responsive over */
    path: '/user_role_add', name: 'user_role_add', component: () => import('../views/user/UserRoleAddView.vue'),
  }, {
    /* responsive over */
    path: '/user_get_back_password',
    name: 'user_get_back_password',
    component: () => import('../views/user/UserGetBackPasswordView.vue'),
  }, {
    /* responsive over */
    path: '/user_modify_password',
    name: 'user_modify_password',
    component: () => import('../views/user/UserModifyPassword.vue'),
  }, {
    /* responsive over */
    // Render over

    path: '/user_home_page_edit',
    name: 'user_home_page_edit',
    component: () => import('../views/user/UserHomePageEditView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/material_center',
    name: 'material_center',
    component: () => import('../views/MaterialCenterView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/plane',
    name: 'plane',
    component: () => import('../views/PlaneCenterView.vue')
  }, {
    path: '/p_detail',
    name: 'p_detail',
    component: () => import('../views/PlaneDetail.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/model_center',
    name: 'model_center',
    component: () => import('../views/ModelCenter.vue')
  }, {
    path: '/m_detail',
    name: 'm_detail',
    component: () => import('../views/MaterialDetail.vue')
  },
  {
    path: '/o_detail',
    name: 'o_detail',
    component: () => import('../views/ModelDetail.vue')
  }
  ,
  {
    path: '/try',
    name: 'try',
    component: () => import('../views/TryEditor3D.vue')
  },
  {
    /* responsive over */
    path: '/my_order',
    name: 'my_order',
    component: () => import('../views/MyOrderView.vue')
  }, {
    /* responsive over */
    path: '/sign_up',
    name: 'sign_up',
    component: () => import('../views/sign/SignUpView.vue')
  },
  {
    /* responsive over */
    path: '/release_requirements',
    name: 'release_requirements',
    component: () => import('../views/ReleaseRequirementsView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/user_home_page_edit',
    name: 'user_home_page_edit',
    component: () => import('../views/user/UserHomePageEditView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/material_center', name: 'material_center', component: () => import('../views/MaterialCenterView.vue')
  }, {
    /* responsive over */
    // Render over
    path: '/model_center', name: 'model_center', component: () => import('../views/ModelCenter.vue')
  }, {
    path: '/m_detail', name: 'm_detail', component: () => import('../views/MaterialDetail.vue')
  }, {
    path: '/o_detail', name: 'o_detail', component: () => import('../views/ModelDetail.vue')
  }, {
    path: '/try', name: 'try', component: () => import('../views/TryEditor3D.vue')
  }, {
    /* responsive over */
    path: '/my_order', name: 'my_order', component: () => import('../views/MyOrderView.vue')
  }, {
    /* responsive over */
    path: '/release_requirements',
    name: 'release_requirements',
    component: () => import('../views/ReleaseRequirementsView.vue')
  }, {
    /* responsive over */
    // Render over

    path: '/stylist_homepage', name: 'stylist_homepage', component: () => import('../views/StylistHomepageView.vue')
  }, {
    /* responsive over */
    path: '/stylist_workvench', name: 'stylist_workvench', component: () => import('../views/StylistWorkvenchView.vue')
  }, {
    /* responsive over */
    path: '/task_add', name: 'task_add', component: () => import('../views/TaskAddView.vue')
  }, {
    /* responsive over */
    path: '/task_details', name: 'task_details', component: () => import('../views/TaskDetailsView.vue')
  }, {
    /* responsive over */
    path: '/task_list', name: 'task_list', component: () => import('../views/TaskListView.vue')
  }, {
    /* responsive over */
    path: '/confirm_purchase',
    name: 'confirm_purchase',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfirmPurchaseView.vue')
  }, {
    /* 三维设计器 */
    path: '/design_editor_3d',
    name: 'design_editor_3d',
    component: () => import(/* webpackChunkName: "about" */ '../views/DesignEditor3D.vue')
  }, {
    /* 设计师主页已登录的用户*/
    path: '/designer_index',
    name: 'designer_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/designer/DesignerIndex.vue')
  }, {
    /* 设计师主页,按uid访问单个设计师主页*/
    path: '/designer',
    name: 'designer',
    component: () => import(/* webpackChunkName: "about" */ '../views/designer/DesignerIndexView.vue')
  }, {
    /* 设计师列表*/
    path: '/dc', name: 'dc', component: () => import(/* webpackChunkName: "about" */ '../views/DesignerCenter.vue')
  }, {
    /* 设计师列表*/
    path: '/design_product', name: 'design_product', component: () => // import(/* webpackChunkName: "about" */ '../views/designer/ProductDesign.vue')
      import(/* webpackChunkName: "about" */ '../views/designer/DesignProduct1.vue')
  }, {
    /* 组合*/
    path: '/design_combine',
    name: 'design_combine',
    component: () => import(/* webpackChunkName: "about" */ '../views/DesignCombine.vue')
  }, {
    /* 移动端三维临时展示用 */
    path: '/mobile_show', name: 'mobile_show', component: () => import('../views/designer/MobileShow.vue')
  }, {
    /* 主题定制 */
    path: '/custom_made', name: 'custom_made', component: () => import('../views/CustomMade.vue')
  }, {
    /* 设计模板 */
    path: '/design_template', name: 'design_template', component: () => import('../views/DesignEditor3D.vue')
  }, {
    /* 移动端设计器 */
    path: '/mobile_design', name: 'mobile_design', component: () => import('../views/mobile/MobileDesign.vue')
  }, {
    /* 移动端保存的设计作品再编辑 */
    path: '/mobile_save_design_show',
    name: 'mobile_save_design_show',
    component: () => import('../views/mobile/MobileSaveDesignShow.vue')
  }, {
    /* 用户设计用品展示*/
    path: '/user_design_show',
    name: 'user_design_show',
    component: () => import(/* webpackChunkName: "about" */ '../views/designer/UserDesignShow.vue')
  },{
    /* 设计师列表*/
    path: '/draw_pattern', name: 'draw_pattern', component: () => // import(/* webpackChunkName: "about" */ '../views/designer/DrawPattern.vue')
      import(/* webpackChunkName: "about" */ '../views/designer/DrawPattern.vue')
  },
  {
    /* 移动端保存的设计作品再编辑 */
    path: '/draw_help',
    name: 'draw_help',
    component: () => import('../views/designer/AiDrawHelp.vue')
  },
  ...userRouter,
  ...competitionRouter,
  ...signRouter,
  ...mobileRouter,
  ...signRouter,
  ...yasoRouter,
  ...templateRouter,
  ...productRouter,
  ...reviewerRouter,
  {
    path: '/:pathMatch(.*)',
    redirect: '/no_pub'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/no_pub'
  }
]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL)
  history: createWebHistory(process.env.BASE_URL)
  , routes
})

export default router
