const subRouter = [
  {
    path: '/user',
    meta: {
      requireLogin: true
    },
    component: () => import('@/views/user/UCView.vue'),
    children: [
      {
        path: '',
        name: 'user_center',
        component: () => import('@/views/user/UserCenterView.vue')
      },
      {
        path: 'bind',
        children: [
          {
            path: '',
            name: 'user_bind_wechat',
            component: () => import('@/views/user/bind/UserBindWechat.vue'),
          }
        ]
      },
      {
        path: 'demand',
        name: 'user_demand',
        component: () => import('../views/user/UserDemandManage.vue')
      },
      {
        path: 'product',
        name: 'user_product',
        component: () => import('../views/user/CheckProduct.vue')
      },
      {
        path: 'templateDrafts',
        name: 'template_drafts',
        component: () => import('../views/user/TemplateDrafts.vue')
      },
      {
        path: 'contract_more',
        name: 'user_contract_more',
        component: () => import('../views/user/MoreContract.vue')
      },
      {
        path: 'competition',
        children: [
          {
            path: '',
            name: 'user_competition',
            component: () => import('@/views/user/competition/UserCompetitionListView.vue'),
          }, {
            path: 'infoUp',
            name: 'user_competition_infoUp',
            component: () => import('@/views/user/competition/UserCompetitionInfoUpView.vue')
          },
        ]
      },
      {
        path: 'designed',
        name: 'user_designed',
        component: () => import('../views/user/DesignProduct.vue')
      },
      {
        path: 'orders',
        name: 'user_orders',
        component: () => import('../views/user/UserOrder.vue')
      },
      {
        path: 'goods_order_detail',
        name: 'goods_order_detail',
        component: () => import('../views/user/orders/goodsOrderDetail.vue')
      },
      {
        path: 'custom_order_detail',
        name: 'custom_order_detail',
        component: () => import('../views/user/orders/customOrderDetail')
      },
      {
        path: 'templateDrafts',
        name: 'template_drafts',
        component: () => import('../views/user/TemplateDrafts.vue')
      },
      {
        path: 'design_template_pub',
        name: 'design_template_pub',
        component: () => import('../views/user/editTemplate.vue')
      },
      {
        path: 'custom_order',
        name: 'custom_order',
        component: () => import('../views/user/CustomOrder.vue')
      },
      {
        path: 'design_custom_order',
        name: 'design_custom_order',
        component: () => import('../views/user/DesignCustomOrder.vue')
      }
    ]
  }
]

export default subRouter
