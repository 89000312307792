import Files from "@/api/files";
import CONST from "@/data/const_config.json";
import ls from "@/utils/localStorage";
import SIGN_API from "@/api/sign";
import {message} from "ant-design-vue";
import store from '../store';

function handlerAvatar(_avatar) {
  return (_avatar?.length > 0) ? Files.ShowImgPath('avatar/' + _avatar) : CONST.user_default_config.avatar
}

function getUserInfo() {
  return ls.getItem('user') ?? null
}

function isLogin() {
  return getUserInfo() !== null
}

function needLogin(tip, callbackFn) {
  callbackFn = callbackFn ?? (() => {
  })
  tip = tip ?? "您尚未登录，请登录后继续操作！"
  if (!isLogin()) {
    message.warn(tip, 3)
    window.location.href = '/sign/in'
  } else {
    callbackFn()
  }
}

async function checkLogin() {
  SIGN_API.isLogin().then(res => {
    if (res.code === 2000) {
      console.log('login:yes')
      const tmpData = JSON.stringify(res.data)
      let rs_ls = JSON.parse(tmpData)
      rs_ls.from = "localStorage"
      ls.setItem('user', rs_ls)
      ls.setItem('user_login_time', new Date().getTime())
      //将登录状态同步到vuex
      store.state.user = rs_ls
      store.state.isLogin = true
      // console.log("state,isLogin",store.state.user,store.state.isLogin)
      return true
    } else {
      ls.clear()
      store.state.isLogin = false
      console.log('login:no')
      return false
    }
  })
}

export default {
  handlerAvatar,
  getUserInfo,
  isLogin,
  checkLogin,
  needLogin
}
