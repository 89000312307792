import {DoubleSide, MeshPhongMaterial, MeshStandardMaterial} from "three";

export const ceramic = () => {
  return new MeshPhongMaterial({
    color: 0xffffff,
    emissive: 0x000000,
    specular: 0xffffff,
    reflectivity: 1,
    shininess: Math.pow(2, 10),
    side: DoubleSide,
  })
}

export const ceramic01 = () => {
  return new MeshPhongMaterial({
    color: 0xffffff,
    bumpScale: 1,
    reflectivity: 1,
    shininess: Math.pow(2, 10),
    side: DoubleSide,
  })
}
const params = {
  envMap: 'EXR',
  roughness: 0.0,
  metalness: 0.0,
  exposure: 1.0,
  debug: false,
};

export const ceramic02 = () => {
  return new MeshStandardMaterial({
    metalness: params.metalness,
    roughness: params.roughness,
    envMapIntensity: 1.0
  });
}
